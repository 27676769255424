/* eslint-disable react/prop-types */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from 'next/dynamic';
import Image from 'next/image';

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

function RotatingCardFront({
  color, image, icon, title, description,
}) {
  return (
    <>
      <Image
        style={{
          zIndex: 1, borderRadius: '0.5rem', backfaceVisibility: 'hidden', objectFit: 'cover', objectPosition: 'cover',
        }}
        src={image}
        alt="title"
        fill
      />
      <MDBox
        display="flex"
        justifyContent="center"
        alignContent="center"
        borderRadius="lg"
        coloredShadow={color}
        width="100%"
        position="relative"
        zIndex={6}
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
          )}`,
          backgroundSize: 'cover',
          backfaceVisibility: 'hidden',
        }}
      >
        <MDBox py={12} px={3} textAlign="center" lineHeight={1}>
          {icon && (
            <MDTypography variant="h2" color="white" my={2}>
              {icon}
            </MDTypography>
          )}
          <MDTypography variant="h3" color="white" gutterBottom>
            {title}
          </MDTypography>
          <MDTypography variant="body2" color="white" opacity={0.8} fontWeight="regular">
            {description}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );
}

export default RotatingCardFront;
